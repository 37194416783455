<template>
  <div class="Main-wrapper">
    <layoutheader></layoutheader>
    <breadcrumb1
      :title="this.$t('available_timings')"
      :text="this.$t('home')"
      :text1="this.$t('available_timings')"
    />

    <!-- Page Content -->
    <div class="content doctor-content">
      <div class="container">
        <div class="row">
          <doctorsidebar></doctorsidebar>

          <div class="col-md-7 col-lg-8 col-xl-9">
            <div class="dashboard-header">
              <h3>{{ $t("available_timings") }}</h3>
            </div>
            <form @submit.prevent="submitForm">
              <div class="csutome-check-day">
                <label
                  :class="isSelected(item.id)"
                  v-for="item in days"
                  :key="item.id"
                  :for="item.id"
                >
                  <span>{{ item.name }}</span>
                  <input
                    type="checkbox"
                    :id="item.id"
                    @change="updateDates($event, item)"
                  />
                </label>
              </div>
              <div
                class="py-3 border-info"
                :class="dateIndex != dates.length - 1 ? 'border-bottom' : ''"
                v-for="(day, dateIndex) in dates"
                :key="dateIndex"
              >
                <div>
                  <label>{{ $t("day") }}: {{ day.name }} </label>
                </div>
                <div v-for="(time, timeIndex) in day.times" :key="timeIndex">
                  <div class="row align-items-end my-3">
                    <div class="col-4">
                      <label>{{ $t("time_from") }}:</label>
                      <input
                        class="form-control"
                        type="time"
                        v-model="time.from"
                      />
                    </div>
                    <div class="col-4">
                      <label>{{ $t("time_to") }}:</label>
                      <input
                        class="form-control"
                        type="time"
                        v-model="time.to"
                      />
                    </div>
                    <div class="col-2">
                      <button
                        class="btn btn-success me-2"
                        type="button"
                        @click="addTime(dateIndex)"
                      >
                        +
                      </button>
                      <button
                        v-if="timeIndex != 0"
                        class="btn btn-danger"
                        type="button"
                        @click="removeTime(dateIndex, timeIndex)"
                      >
                        -
                      </button>
                    </div>
                    <div class="col-4">
                      <div
                        v-if="
                          v$.$error &&
                          v$.dates.$each.$response.$data[dateIndex].times.$each
                            .$data[timeIndex].from.$invalid
                        "
                      >
                        <span class="text-warning d-block">
                          {{ $t("validation.requiredField") }}
                        </span>
                      </div>
                    </div>
                    <div class="col-4">
                      <div
                        v-if="
                          v$.$error &&
                          v$.dates.$each.$response.$data[dateIndex].times.$each
                            .$data[timeIndex].to.$invalid
                        "
                      >
                        <span
                          v-if="
                            !v$.dates.$each.$response.$data[dateIndex].times
                              .$each.$data[timeIndex].to.required
                          "
                          class="text-warning d-block"
                        >
                          {{ $t("validation.requiredField") }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <hr v-if="dateIndex != dates.length - 1" />
              </div>
              <div class="py-4">
                <button class="btn btn-info" type="submit">
                  <span v-if="!isloading">{{ $t("submit") }}</span>
                  <b-spinner v-else></b-spinner>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <footerindex></footerindex>
  </div>
</template>

<script>
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { mapGetters } from "vuex";
import { addAvailableDates, getAvailableTtimes } from "@/api/doctor";
import { getDaysOfAvailableTime } from "@/api/lookups";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      isloading: false,
      dates: [],
      days: [],
    };
  },
  validations() {
    return {
      dates: {
        $each: helpers.forEach({
          day: { required },
          times: {
            $each: helpers.forEach({
              from: { required },
              to: { required },
            }),
          },
        }),
      },
    };
  },
  computed: {
    ...mapGetters(["currentDate"]),
  },
  created() {
    this.getDays();
  },
  methods: {
    async getDays() {
      try {
        let response = await getDaysOfAvailableTime();
        if (response) {
          this.days = response.data;

          let previousDays = await getAvailableTtimes();
          let previousDaysData = previousDays.data;

          const result = { day: [] };

          if (previousDaysData) {
            previousDaysData.forEach((appointment) => {
              const dayIndex = result.day.findIndex(
                (day) => day.num == appointment.day
              );

              if (dayIndex > -1) {
                result.day[dayIndex].times.push({
                  id: appointment.id,
                  from: appointment.time_from,
                  to: appointment.time_to,
                });
              } else {
                result.day.push({
                  num: Number(appointment.day),
                  name: appointment.day_name,
                  times: [
                    {
                      id: appointment.id,
                      from: appointment.time_from,
                      to: appointment.time_to,
                    },
                  ],
                });
              }
            });
            this.dates = result.day;
          }
        }
      } catch (error) {
        console.error("Error fetching days:", error);
      }
    },
    updateDates(event, item) {
      const timeEntry = {
        num: item.id,
        name: item.name,
        times: [{ from: "", to: "" }],
      };
      if (event.target.checked) {
        // Add the timeEntry to dates if checked
        this.dates.push(timeEntry);
      } else {
        // Remove the timeEntry from dates if unchecked
        this.dates = this.dates.filter((entry) => entry.num !== item.id);
      }
    },
    isSelected(id) {
      let arr = this.dates.map((el) => el.num);
      if (arr.includes(id)) {
        return "selected";
      }
      return "";
    },
    addTime(dateIndex) {
      this.dates[dateIndex].times.push({ from: "", to: "" });
    },
    removeTime(dateIndex, timeIndex) {
      this.dates[dateIndex].times.splice(timeIndex, 1);
    },
    async submitForm() {
      this.v$.$touch();
      if (this.v$.$error) {
        return;
      }
      this.isloading = true;

      const payload = {
        day: this.dates,
      };
      try {
        let res = await addAvailableDates(payload);
        if (res) {
          this.getDays();
          this.v$.$reset();
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isloading = false;
      }
    },
  },
};
</script>
<style scoped>
.csutome-check-day {
  display: flex;
  flex-wrap: wrap;
}
.csutome-check-day label {
  background: #eee;
  padding: 5px 7px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 5px;
}
.csutome-check-day label.selected {
  background: #0e82fd;
  color: #fff;
}
.csutome-check-day input {
  display: none;
}
</style>
