<template>
  <div class="main-wrapper">
    <layoutheader></layoutheader>
    <breadcrumb1
      :title="this.$t('appointments')"
      :text="this.$t('home')"
      :text1="this.$t('appointments')"
    />

    <!-- Page Content -->
    <div class="content">
      <div class="container">
        <div class="row">
          <doctorsidebar></doctorsidebar>

          <div class="col-md-7 col-lg-8 col-xl-9">
            <div class="dashboard-header">
              <h3>{{ $t("appointments") }}</h3>
            </div>
            <div class="appointment-tab-head">
              <form action="" class="flex-grow-1">
                <div class="row">
                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <select class="form-control" v-model="searchModel.status">
                      <option :value="null" selected>
                        {{ $t("all_status") }}
                      </option>
                      <option
                        v-for="(item, i) in statusList"
                        :key="i"
                        :value="item"
                      >
                        {{ item }}
                      </option>
                    </select>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <input
                      class="form-control"
                      type="date"
                      v-model="searchModel.date_from"
                    />
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <input
                      class="form-control"
                      type="date"
                      v-model="searchModel.date_to"
                    />
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-6">
                    <button
                      style="width: 140px; padding: 10px"
                      class="btn btn-primary"
                      :disabled="loading"
                      @click="(searchModel.page = 1), getDataList()"
                    >
                      {{ $t("search") }}
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div class="d-flex justify-content-center py-5" v-if="loading">
              <div
                class="spinner-border"
                style="width: 3rem; height: 3rem"
                role="status"
              ></div>
            </div>
            <div class="py-4" v-else-if="!loading && searchResult.length">
              <div class="" v-for="(item, i) in searchResult" :key="i">
                <div class="appointment-wrap">
                  <ul>
                    <li>
                      <div class="patinet-information">
                        <div>
                          <img :src="item.patient.photo" alt="User Image" />
                        </div>
                        <div class="patient-info">
                          <p>#Apt0002</p>
                          <h6>
                            <div>
                              {{ item.patient_name }}
                            </div>
                            <span
                              class="badge ms-2"
                              :class="
                                item.status == 'cancelled'
                                  ? 'bg-danger'
                                  : 'bg-info'
                              "
                            >
                              {{ item.status }}
                            </span>
                          </h6>
                        </div>
                      </div>
                    </li>
                    <li class="appointment-info">
                      <p>
                        <i class="fa-solid fa-clock"></i>
                        {{ item.time_from }} - {{ item.time_to }}
                      </p>
                      <ul class="d-flex apponitment-types">
                        <li>{{ item.day }}</li>
                        <li>{{ item.date }}</li>
                      </ul>
                    </li>
                    <li class="mail-info-patient">
                      <ul>
                        <li>
                          <i class="fa-solid fa-envelope"></i>
                          {{ item.patient_email }}
                        </li>
                        <li>
                          <i class="fa-solid fa-phone"></i>
                          {{ item.patient.mobile }}
                        </li>
                      </ul>
                    </li>
                    <li v-if="item.status != 'cancelled'">
                      <a
                        class="text-white bg-danger btn"
                        :class="item.status == 'cancelled' ? '' : ''"
                        @click="cnacelAppointment(item)"
                        href="javascript:void(0);"
                        >{{ $t("cancel") }}
                      </a>
                      <a
                        v-if="item.meeting_link"
                        class="btn bg-info ms-2"
                        target="_blank"
                        :href="item.meeting_link"
                      >
                        <img
                          style="width: 23px"
                          src="@/assets/img/icons/customer-service-1.svg"
                          alt=""
                        />
                      </a>
                    </li>
                    <li class="appointment-start">
                      <router-link
                        :to="`/doctor-appointment-details?app=${item.id}`"
                        class="start-link"
                        >{{ $t("details") }}</router-link
                      >
                    </li>
                  </ul>
                </div>
              </div>
              <upcoming></upcoming>
              <pagination
                :pagination="pagination"
                @changePage="gotoPage"
              ></pagination>
            </div>
            <div class="d-flex justify-content-center py-5" v-else>
              {{ $t("noSearchResult") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <footerindex></footerindex>
  </div>
</template>

<script>
import { doctorAppointments, cancelDoctorAppointments } from "@/api/doctor";
import pagination from "@/components/frontend/pagination/index.vue";

export default {
  data() {
    return {
      title: "Appointments",
      text: "Home",
      text1: "Appointments",
      loading: false,
      statusList: ["request", "accepted", "done", "cancelled"],
      pagination: {
        pageNumber: 1,
        totalCount: 0,
        totalPages: 0,
        per_page: 0,
      },
      searchResult: [],
      searchModel: {
        status: null,
        date_from: "",
        date_to: "",
        page: "",
      },
    };
  },
  components: {
    pagination,
  },
  created() {
    this.loadSearchModelFromQuery();
    this.getDataList();
  },
  methods: {
    loadSearchModelFromQuery() {
      const query = this.$route.query;
      if (query) {
        this.searchModel.date_from = query.date_from;
        this.searchModel.date_to = query.date_to;
        this.searchModel.page = query.page;
        this.searchModel.status = query.status;
      }
    },
    async getDataList() {
      this.loading = true;

      try {
        const response = await doctorAppointments(this.searchModel);

        if (response) {
          this.searchResult = response.data.data;
          this.pagination.totalPages = response.data.meta.last_page;
          this.pagination.totalCount = response.data.meta.total;
          this.pagination.pageNumber = response.data.meta.current_page;
          this.pagination.per_page = response.data.meta.per_page;
          this.updateQueryParams();
        }
      } catch (error) {
        console.error("Error fetching roles list:", error);
      } finally {
        this.loading = false;
      }
    },
    updateQueryParams() {
      this.$router.push({
        query: {
          date_from: this.searchModel.date_from,
          date_to: this.searchModel.date_to,
          status: this.searchModel.status,
          page: this.searchModel.page,
        },
      });
    },
    gotoPage(page) {
      this.pagination.pageNumber = Number(page);
      this.$router.push({ query: { ...this.$route.query, page: page } });
      this.getDataList();
    },
    async cnacelAppointment(item) {
      try {
        let res = await cancelDoctorAppointments(item.id);
        if (res) {
          item.status = "cancelled";
        }
      } catch (error) {
        console.error("Error fetching roles list:", error);
      }
    },
  },
};
</script>
