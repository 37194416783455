import { getUserLang, setUserLang } from "@/utils/helpers";
import { register, login } from "@/api/user";
import {
  getToken,
  setToken,
  setUserData,
  removeToken,
  removeUserData,
  getUserData,
} from "@/utils/auth";
import { createStore } from "vuex";
import { router } from "@/router";

export const state = () => ({
  currentScrollY: 0,
  translatedVariables: {},
  isLoggedIn: false,
  userData: null,
  token: null,
  userLang: navigator.language || "en",
  currentDate: "",
  responseMessage: {
    message: "",
    open: false,
  },
});

export const getters = {
  currentScrollY: (state) => state.currentScrollY,
  currentLang: (state) => state.userLang,
  isTranslatedVariables: (state) => state.translatedVariables,
  isLoggedIn: (state) => state.isLoggedIn,
  userData: (state) => state.userData,
  token: (state) => state.token,
  currentDate: (state) => state.currentDate,
};

export const mutations = {
  setCurrentScrollY(state, value) {
    state.currentScrollY = value;
  },
  setUserLang(state, lang) {
    const userLang = lang || getUserLang() || "en";
    setUserLang(userLang);
    state.userLang = userLang;
    if (this.$i18n) {
      this.$i18n.setLocale(userLang);
    }
  },
  setTranslatedVariables(state, payload) {
    state.translatedVariables = payload;
  },
  SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_MESSAGE(state, data) {
    state.responseMessage = data;
  },
  SET_USERDATA(state, data) {
    state.userData = data;
  },
  SET_IS_LOGGED_IN(state, value) {
    state.isLoggedIn = value;
  },
  GET_CURRENT_DATE(state) {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    state.currentDate = `${year}-${month}-${day}`;
  },
};

export const actions = {
  async handleLogin({ commit }, data) {
    try {
      const response = await login(data.payload);
      const { email, id, doctor, patient } = response.data.user;
      const doctorOrPtient = doctor?.id ? "isDoctor" : "isPatient";
      doctorOrPtient == "isDoctor"
        ? router.push("/doctor-dashboard")
        : router.push("/");
      const name = doctor?.id ? doctor?.name : patient?.name;
      const user = {
        name,
        email,
        id,
        doctor,
        patient,
        doctorOrPtient,
      };

      commit("SET_USERDATA", user);
      setUserData(JSON.stringify(user));

      const accessToken = response.data.token;
      commit("SET_TOKEN", accessToken);
      setToken(accessToken);

      commit("SET_IS_LOGGED_IN", true);
    } catch (error) {
      throw error;
    }
  },
  async handleLoginWithToken({ commit }, response) {
    try {
      const { email, id, patient } = response.data.user;
      const doctorOrPtient = "isPatient";
      router.push("/");
      const name = patient?.name;
      const user = {
        name,
        email,
        id,
        patient,
        doctorOrPtient,
      };

      commit("SET_USERDATA", user);
      setUserData(JSON.stringify(user));

      const accessToken = response.data.token;
      commit("SET_TOKEN", accessToken);
      setToken(accessToken);

      commit("SET_IS_LOGGED_IN", true);
    } catch (error) {
      throw error;
    }
  },
  handleAutoLogin({ commit }) {
    const token = getToken();
    const userData = getUserData();

    let user = {};
    try {
      user = userData ? JSON.parse(userData) : {};
    } catch (error) {
      console.error("Error parsing user data:", error);
      user = {};
    }

    const lang = getUserLang() || "en";

    commit("SET_USERDATA", user);
    commit("SET_IS_LOGGED_IN", !!user && Object.keys(user).length > 0);
    commit("SET_TOKEN", token);
    commit("setUserLang", lang);
  },

  async logout({ commit }) {
    commit("SET_USERDATA", null);
    commit("SET_TOKEN", null);
    removeUserData();
    removeToken();
    commit("SET_IS_LOGGED_IN", false);
    router.push("/");
  },
  handleUserLang({ commit }, lang) {
    commit("setUserLang", lang);
  },
};

export default createStore({
  state,
  getters,
  mutations,
  actions,
});
