<template>
  <!-- Add Medical Detail -->
  <div class="modal fade custom-modals" id="add-med-record">
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add Medical Details</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>
        <form @submit.prevent="submitForm">
          <div class="modal-body">
            <div class="timing-modal">
              <div class="row">
                <div class="col-md-6">
                  <div class="input-block input-block-new">
                    <label class="form-label">BMI</label>
                    <input type="text" class="form-control" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-block input-block-new">
                    <label class="form-label">Heart Rate</label>
                    <input type="text" class="form-control" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-block input-block-new">
                    <label class="form-label">Weight</label>
                    <input type="text" class="form-control" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-block input-block-new">
                    <label class="form-label">FBC</label>
                    <input type="text" class="form-control" />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="input-block input-block-new">
                    <label class="col-form-label"
                      >End Date <span class="text-danger">*</span></label
                    >
                    <div class="form-icon position-relative">
                      <input type="text" class="form-control datetimepicker" />
                      <span class="icon cal-form-icon"
                        ><i class="fa-regular fa-calendar-days"></i
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="modal-btn text-end">
              <button type="submit" class="btn btn-primary prime-btn">
                Save Changes
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- /Add Medical Detail -->

  <!-- Edit Medical Detail -->
  <div class="modal fade custom-modals" id="edit-med-record">
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Edit Medical Details</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>
        <form @submit.prevent="submitFormOne">
          <div class="modal-body">
            <div class="timing-modal">
              <div class="row">
                <div class="col-md-6">
                  <div class="input-block input-block-new">
                    <label class="form-label">BMI</label>
                    <input
                      type="text"
                      class="form-control"
                      value="20.1 kg/m2"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-block input-block-new">
                    <label class="form-label">Heart Rate</label>
                    <input type="text" class="form-control" value="140 Bpm" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-block input-block-new">
                    <label class="form-label">Weight</label>
                    <input type="text" class="form-control" value="300" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-block input-block-new">
                    <label class="form-label">FBC</label>
                    <input type="text" class="form-control" value="70 - 90" />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="input-block input-block-new">
                    <label class="col-form-label"
                      >End Date <span class="text-danger">*</span></label
                    >
                    <div class="form-icon position-relative">
                      <input type="text" class="form-control datetimepicker" />
                      <span class="icon cal-form-icon"
                        ><i class="fa-regular fa-calendar-days"></i
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="modal-btn text-end">
              <button type="submit" class="btn btn-primary prime-btn">
                Save Changes
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- /Edit Medical Detail -->

  <!-- Medical Detail -->
  <div class="modal fade custom-modals" id="med-detail">
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Medical Details</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>
        <form @submit.prevent="submitFormTwo">
          <div class="modal-body pb-0">
            <div class="med-detail-patient">
              <div class="med-patient">
                <span
                  ><img
                    src="@/assets/img/doctors-dashboard/profile-06.jpg"
                    alt="Img"
                /></span>
                <div class="name-detail">
                  <h5>Hendrita Kearns</h5>
                  <ul>
                    <li>Age : 42</li>
                    <li>Female</li>
                    <li>AB+ve</li>
                  </ul>
                </div>
              </div>
              <div class="date-cal">
                <p>
                  <span
                    ><i class="fa-solid fa-calendar-days me-2"></i>Last
                    Updated</span
                  >24 Mar 2024
                </p>
              </div>
            </div>
            <div class="med-detail-item pb-3">
              <div class="d-flex flex-wrap">
                <div class="health-records icon-red">
                  <span><i class="fa-solid fa-syringe"></i>Blood Pressure</span>
                  <h3>100 mg/dl</h3>
                </div>
                <div class="health-records icon-orange">
                  <span><i class="fa-solid fa-heart"></i>Heart Rate</span>
                  <h3>140 Bpm</h3>
                </div>
                <div class="health-records icon-dark-blue">
                  <span
                    ><i class="fa-solid fa-notes-medical"></i>Glucose
                    Level</span
                  >
                  <h3>70 - 90</h3>
                </div>
                <div class="health-records icon-amber mb-0">
                  <span
                    ><i class="fa-solid fa-temperature-high"></i>Body
                    Temprature</span
                  >
                  <h3>37.5 C</h3>
                </div>
                <div class="health-records icon-purple mb-0">
                  <span><i class="fa-solid fa-user-pen"></i>BMI </span>
                  <h3>20.1 kg/m2</h3>
                </div>
                <div class="health-records icon-blue mb-0">
                  <span><i class="fa-solid fa-highlighter"></i>SPo2</span>
                  <h3>96%</h3>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- /Medical Detail -->
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    submitForm() {
      this.$router.push("/medical-details");
    },
    submitFormOne() {
      this.$router.push("/medical-details");
    },
    submitFormTwo() {
      this.$router.push("/medical-details");
    },
  },
};
</script>
