<template>
  <div class="main-wrapper">
    <layoutheader></layoutheader>
    <breadcrumb1 :title="title" :text="text" :text1="text1" />

    <!-- Page Content -->
    <div class="content">
      <div class="container">
        <div class="row">
          <doctorsidebar></doctorsidebar>

          <div class="col-lg-8 col-xl-9">
            <div class="dashboard-header">
              <div class="header-back">
                <router-link to="/appointments" class="back-arrow"
                  ><i class="fa-solid fa-arrow-left"></i
                ></router-link>
                <h3>Appointment Details</h3>
              </div>
            </div>
            <div class="appointment-details-wrap">
              <!-- Appointment Detail Card -->
              <div class="appointment-wrap appointment-detail-card">
                <ul>
                  <li>
                    <div class="patinet-information">
                      <a href="#">
                        <img
                          src="@/assets/img/doctors-dashboard/profile-02.jpg"
                          alt="User Image"
                        />
                      </a>
                      <div class="patient-info">
                        <p>#Apt0001</p>
                        <h6><a href="#">Kelly Joseph </a></h6>
                        <div class="mail-info-patient">
                          <ul>
                            <li>
                              <i class="fa-solid fa-envelope"></i
                              >kelly@example.com
                            </li>
                            <li>
                              <i class="fa-solid fa-phone"></i>+1 504 368 6874
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="appointment-info">
                    <div class="person-info">
                      <p>Person with patient</p>
                      <ul class="d-flex apponitment-types">
                        <li>Andrew</li>
                      </ul>
                    </div>
                    <div class="person-info">
                      <p>Type of Appointment</p>
                      <ul class="d-flex apponitment-types">
                        <li>
                          <i class="fa-solid fa-video text-indigo"></i>Video
                          Call
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li class="appointment-action">
                    <div class="detail-badge-info">
                      <span class="badge bg-red me-2">Cancelled</span>
                      <a
                        href="#reject_reason"
                        class="reject-popup"
                        data-bs-toggle="modal"
                        >Reason</a
                      >
                    </div>
                    <div class="consult-fees">
                      <h6>Consultation Fees : $200</h6>
                    </div>
                    <ul>
                      <li>
                        <a href="#"><i class="fa-solid fa-comments"></i></a>
                      </li>
                      <li>
                        <a href="#"><i class="fa-solid fa-xmark"></i></a>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul class="detail-card-bottom-info">
                  <li>
                    <h6>Appointment Date & Time</h6>
                    <span>22 Jul 2023 - 12:00 pm</span>
                  </li>
                  <li>
                    <h6>Visit Type</h6>
                    <span>General</span>
                  </li>
                  <li>
                    <div class="detail-badge-info">
                      <span class="badge bg-soft-red me-2"
                        >Status : Reschedule</span
                      >
                      <a href="#" class="reschedule-btn btn-primary-border"
                        >Reschedule Appointment</a
                      >
                    </div>
                  </li>
                </ul>
              </div>
              <!-- /Appointment Detail Card -->

              <div class="recent-appointments">
                <h5 class="head-text">Recent Appointments</h5>
                <!-- Appointment List -->
                <div class="appointment-wrap">
                  <ul>
                    <li>
                      <div class="patinet-information">
                        <a href="#">
                          <img
                            src="@/assets/img/doctors-dashboard/profile-01.jpg"
                            alt="User Image"
                          />
                        </a>
                        <div class="patient-info">
                          <p>#Apt0001</p>
                          <h6><a href="#">Adrian</a></h6>
                        </div>
                      </div>
                    </li>
                    <li class="appointment-info">
                      <p>
                        <i class="fa-solid fa-clock"></i>11 Nov 2024 10.45 AM
                      </p>
                      <ul class="d-flex apponitment-types">
                        <li>General Visit</li>
                      </ul>
                    </li>
                    <li class="mail-info-patient">
                      <ul>
                        <li>
                          <i class="fa-solid fa-envelope"></i>adran@example.com
                        </li>
                        <li>
                          <i class="fa-solid fa-phone"></i>+1 504 368 6874
                        </li>
                      </ul>
                    </li>
                    <li class="appointment-action">
                      <ul>
                        <li>
                          <a href="#"><i class="fa-solid fa-eye"></i></a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <!-- /Appointment List -->
                <!-- Appointment List -->
                <div class="appointment-wrap">
                  <ul>
                    <li>
                      <div class="patinet-information">
                        <a href="#">
                          <img
                            src="@/assets/img/doctors-dashboard/profile-03.jpg"
                            alt="User Image"
                          />
                        </a>
                        <div class="patient-info">
                          <p>#Apt0003</p>
                          <h6><a href="#">Samuel</a></h6>
                        </div>
                      </div>
                    </li>
                    <li class="appointment-info">
                      <p>
                        <i class="fa-solid fa-clock"></i>27 Oct 2024 09.30 AM
                      </p>
                      <ul class="d-flex apponitment-types">
                        <li>General Visit</li>
                        <li>Video Call</li>
                      </ul>
                    </li>
                    <li class="mail-info-patient">
                      <ul>
                        <li>
                          <i class="fa-solid fa-envelope"></i>samuel@example.com
                        </li>
                        <li>
                          <i class="fa-solid fa-phone"></i> +1 749 104 6291
                        </li>
                      </ul>
                    </li>
                    <li class="appointment-action">
                      <ul>
                        <li>
                          <a href="#"><i class="fa-solid fa-eye"></i></a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <!-- /Appointment List -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->

    <footerindex></footerindex>
  </div>
  <appointment-cancel-modal></appointment-cancel-modal>
</template>

<script>
export default {
  data() {
    return {
      title: "Appointment Detail",
      text: "Home",
      text1: "Appointment Detail",
    };
  },
};
</script>
