<template>
  <div class="account-page">
    <div class="main-wrapper">
      <layoutheader></layoutheader>

      <!-- Page Content -->
      <div class="content top-space">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-8 offset-md-2">
              <!-- Account Content -->
              <div class="account-content">
                <div class="row align-items-center justify-content-center">
                  <div class="col-md-7 col-lg-6 login-left">
                    <img
                      src="@/assets/img/login-banner.png"
                      class="img-fluid"
                      alt="Login Banner"
                    />
                  </div>
                  <div class="col-md-12 col-lg-6 login-right">
                    <div class="login-header">
                      <h3>
                        {{ $t("register.forgotPassword") }}
                      </h3>
                      <p class="small text-muted">
                        {{ $t("register.EnterEmailToPasswordResetLink") }}
                      </p>
                    </div>

                    <!-- Forgot Password Form -->
                    <div>
                      <div class="mb-3 form-focus">
                        <input
                          v-model="email"
                          type="email"
                          placeholder=" "
                          class="form-control floating"
                        />
                        <label class="focus-label">
                          {{ $t("register.email") }}
                        </label>

                        <div class="py-2" v-if="v$.email.$error">
                          <small
                            v-if="v$.email.required.$invalid"
                            class="text-danger d-block"
                          >
                            {{ $t("validation.requiredField") }}
                          </small>
                          <small
                            v-else-if="v$.email.email.$invalid"
                            class="text-danger d-block"
                          >
                            {{ $t("validation.email") }}
                          </small>
                        </div>
                      </div>
                      <div class="text-end">
                        <router-link class="forgot-link" to="/login">
                          {{ $t("register.RememberYourPassword") }}
                        </router-link>
                      </div>
                      <b-button
                        class="btn btn-primary w-100 btn-lg login-btn"
                        @click="submitForm"
                        :disabled="loading"
                      >
                        <span v-if="!loading">
                          {{ $t("register.resetPassword") }}
                        </span>
                        <b-spinner v-else></b-spinner>
                      </b-button>
                    </div>
                    <!-- /Forgot Password Form -->
                  </div>
                </div>
              </div>
              <!-- /Account Content -->
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Content -->
    </div>
    <footerindex></footerindex>
  </div>
</template>
<script>
import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";

import { forgotPassword } from "@/api/user";
import { setForgotMail } from "@/utils/auth";

export default {
  setup() {
    return {
      v$: useVuelidate(),
    };
  },

  data() {
    return {
      email: "",
      loading: false,
    };
  },
  validations: {
    email: {
      required,
      email,
    },
  },

  methods: {
    async submitForm() {
      this.v$.$touch();
      if (this.v$.$error) {
        return;
      }
      this.loading = true;

      let payload = new FormData();
      payload.append("email", this.email);

      let response = await forgotPassword(payload, this.$i18n.locale);

      if (response) {
        setForgotMail(this.email);
        this.$router.push(`/reset-password`);
      }
      this.loading = false;
    },
  },
};
</script>
