<template>
  <div class="">
    <form class="row gy-3">
      <div class="col-lg-6">
        <div class="form-focus h-auto">
          <input
            type="text"
            placeholder=" "
            class="form-control floating"
            v-model="patient.first_name"
          />
          <label class="focus-label">{{ $t("register.first_name") }}</label>

          <div class="py-2" v-if="v$.first_name.$error">
            <small
              v-if="v$.first_name.required.$invalid"
              class="text-warning d-block"
            >
              {{ $t("validation.requiredField") }}
            </small>
          </div>
        </div>
      </div>
      <!-- last_name -->
      <div class="col-lg-6">
        <div class="form-focus h-auto">
          <input
            type="text"
            class="form-control floating"
            placeholder=" "
            v-model="patient.last_name"
          />
          <label class="focus-label">{{ $t("register.last_name") }}</label>

          <div class="py-2" v-if="v$.last_name.$error">
            <small
              v-if="v$.last_name.required.$invalid"
              class="text-warning d-block"
            >
              {{ $t("validation.requiredField") }}
            </small>
          </div>
        </div>
      </div>

      <!-- email -->
      <div class="col-lg-6">
        <div class="form-focus h-auto">
          <input
            type="email"
            class="form-control floating"
            placeholder=" "
            v-model="patient.email"
          />
          <label class="focus-label">{{ $t("register.email") }}</label>

          <div class="py-2" v-if="v$.email.$error">
            <small
              v-if="v$.email.required.$invalid"
              class="text-warning d-block"
            >
              {{ $t("validation.requiredField") }}
            </small>
            <small
              v-else-if="v$.email.email.$invalid"
              class="text-warning d-block"
            >
              {{ $t("validation.email") }}
            </small>
          </div>
        </div>
      </div>

      <!-- mobile -->
      <div class="col-lg-6">
        <div class="form-focus h-auto">
          <vue-tel-input
            @validate="checkphone"
            class="form-control floating"
            mode="international"
          ></vue-tel-input>

          <!-- <label class="focus-label">{{ $t("register.mobile") }}</label> -->

          <div class="py-2" v-if="v$.mobile.$error">
            <small
              v-if="v$.mobile.required.$invalid"
              class="text-warning d-block"
            >
              {{ $t("validation.requiredField") }}
            </small>
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="form-wrap mb-0">
          <label class="col-form-label">{{ $t("upload.photo") }}</label>
          <div class="upload-file">
            <input
              type="file"
              @change="patient.photo = $event.target.files[0]"
            />
            <p>
              {{
                patient.photo ? patient.photo.name : $t("upload.instructions")
              }}
            </p>
          </div>
        </div>

        <div class="py-2" v-if="v$.photo.$error">
          <small v-if="v$.photo.required.$invalid" class="text-warning d-block">
            {{ $t("validation.requiredField") }}
          </small>
        </div>
      </div>
      <!-- nationality_id -->
      <div class="col-lg-6">
        <div class="form-wrap">
          <label class="col-form-label">{{ $t("register.nationality") }}</label>
          <vue-multiselect
            v-model="patient.nationality_id"
            track-by="id"
            label="lang"
            :options="countries"
          ></vue-multiselect>

          <div class="py-2" v-if="v$.nationality_id.$error">
            <small
              v-if="v$.nationality_id.required.$invalid"
              class="text-warning d-block"
            >
              {{ $t("validation.requiredField") }}
            </small>
          </div>
        </div>
      </div>
      <!-- country_id -->
      <div class="col-lg-6">
        <div class="form-wrap">
          <label class="col-form-label">{{ $t("register.country") }}</label>
          <vue-multiselect
            v-model="patient.country_id"
            track-by="id"
            label="lang"
            :options="countries"
          ></vue-multiselect>

          <div class="py-2" v-if="v$.country_id.$error">
            <small
              v-if="v$.country_id.required.$invalid"
              class="text-warning d-block"
            >
              {{ $t("validation.requiredField") }}
            </small>
          </div>
        </div>
      </div>

      <!-- gender -->
      <div class="col-lg-6">
        <div class="form-wrap">
          <label class="col-form-label">{{ $t("register.gender") }}</label>
          <vue-multiselect
            v-model="patient.gender"
            track-by="id"
            label="text"
            :options="genders"
          ></vue-multiselect>

          <div class="py-2" v-if="v$.gender.$error">
            <small
              v-if="v$.gender.required.$invalid"
              class="text-warning d-block"
            >
              {{ $t("validation.requiredField") }}
            </small>
          </div>
        </div>
      </div>

      <!-- birthdate -->
      <div class="col-md-6">
        <div class="form-wrap mb-0">
          <label class="col-form-label"> {{ $t("register.birthdate") }} </label>
          <div class="form-icon">
            <datepicker
              v-model="patient.date_of_birth"
              class="datetimepicker form-control"
              placeholder="Enter your birthdate"
              :input-format="dateFormat"
            />
            <span class="icon"
              ><i class="fa-regular fa-calendar-days"></i
            ></span>
          </div>

          <div class="py-2" v-if="v$.date_of_birth.$error">
            <small
              v-if="v$.date_of_birth.required.$invalid"
              class="text-warning d-block"
            >
              {{ $t("validation.requiredField") }}
            </small>
          </div>
        </div>
      </div>
      <!-- national_id_number -->
      <div class="col-lg-6 d-flex align-items-end">
        <div class="form-focus h-auto w-100">
          <input
            type="text"
            class="form-control floating"
            placeholder=" "
            v-model="patient.national_id_number"
          />
          <label class="focus-label">{{
            $t("register.national_id_number")
          }}</label>

          <div class="py-2" v-if="v$.last_name.$error">
            <small
              v-if="v$.national_id_number.required.$invalid"
              class="text-warning d-block"
            >
              {{ $t("validation.requiredField") }}
            </small>
          </div>
        </div>
      </div>

      <!-- chronic_diseases -->
      <div class="col-lg-6 d-flex align-items-end">
        <div class="form-focus h-auto w-100">
          <input
            type="text"
            class="form-control floating"
            placeholder=" "
            v-model="patient.chronic_diseases"
          />
          <label class="focus-label">{{
            $t("register.chronic_diseases")
          }}</label>

          <div class="py-2" v-if="v$.chronic_diseases.$error">
            <small
              v-if="v$.chronic_diseases.required.$invalid"
              class="text-warning d-block"
            >
              {{ $t("validation.requiredField") }}
            </small>
          </div>
        </div>
      </div>

      <!-- endorsement -->
      <div class="col-lg-6 d-flex align-items-end">
        <div class="form-focus h-auto w-100">
          <input
            type="text"
            class="form-control floating"
            placeholder=" "
            v-model="patient.endorsement"
          />
          <label class="focus-label">{{ $t("register.endorsement") }}</label>

          <div class="py-2" v-if="v$.endorsement.$error">
            <small
              v-if="v$.endorsement.required.$invalid"
              class="text-warning d-block"
            >
              {{ $t("validation.requiredField") }}
            </small>
          </div>
        </div>
      </div>

      <!-- current_medications -->
      <div class="col-lg-6 d-flex align-items-end">
        <div class="form-focus h-auto w-100">
          <input
            type="text"
            class="form-control floating"
            placeholder=" "
            v-model="patient.current_medications"
          />
          <label class="focus-label">{{
            $t("register.current_medications")
          }}</label>

          <div class="py-2" v-if="v$.current_medications.$error">
            <small
              v-if="v$.current_medications.required.$invalid"
              class="text-warning d-block"
            >
              {{ $t("validation.requiredField") }}
            </small>
          </div>
        </div>
      </div>
      <!-- medical_allergies -->
      <div class="col-lg-6 d-flex align-items-end">
        <div class="form-focus h-auto w-100">
          <input
            type="text"
            class="form-control floating"
            placeholder=" "
            v-model="patient.medical_allergies"
          />
          <label class="focus-label">{{
            $t("register.medical_allergies")
          }}</label>

          <div class="py-2" v-if="v$.medical_allergies.$error">
            <small
              v-if="v$.medical_allergies.required.$invalid"
              class="text-warning d-block"
            >
              {{ $t("validation.requiredField") }}
            </small>
          </div>
        </div>
      </div>

      <!-- chronic_conditions -->
      <div class="col-lg-6 d-flex align-items-end">
        <div class="form-focus h-auto w-100">
          <input
            type="text"
            class="form-control floating"
            placeholder=" "
            v-model="patient.chronic_conditions"
          />
          <label class="focus-label">{{
            $t("register.chronic_conditions")
          }}</label>

          <div class="py-2" v-if="v$.chronic_conditions.$error">
            <small
              v-if="v$.chronic_conditions.required.$invalid"
              class="text-warning d-block"
            >
              {{ $t("validation.requiredField") }}
            </small>
          </div>
        </div>
      </div>

      <!-- previous_surgeries -->
      <div class="col-lg-6 d-flex align-items-end">
        <div class="form-focus h-auto w-100">
          <input
            type="text"
            class="form-control floating"
            placeholder=" "
            v-model="patient.previous_surgeries"
          />
          <label class="focus-label">{{
            $t("register.previous_surgeries")
          }}</label>

          <div class="py-2" v-if="v$.previous_surgeries.$error">
            <small
              v-if="v$.previous_surgeries.required.$invalid"
              class="text-warning d-block"
            >
              {{ $t("validation.requiredField") }}
            </small>
          </div>
        </div>
      </div>

      <!-- laboratory_tests -->
      <div class="col-lg-6 d-flex align-items-end">
        <div class="form-focus h-auto w-100">
          <input
            type="text"
            class="form-control floating"
            placeholder=" "
            v-model="patient.laboratory_tests"
          />
          <label class="focus-label">{{
            $t("register.laboratory_tests")
          }}</label>

          <div class="py-2" v-if="v$.laboratory_tests.$error">
            <small
              v-if="v$.laboratory_tests.required.$invalid"
              class="text-warning d-block"
            >
              {{ $t("validation.requiredField") }}
            </small>
          </div>
        </div>
      </div>

      <!-- physical_activity -->
      <div class="col-lg-6 d-flex align-items-end">
        <div class="form-focus h-auto w-100">
          <input
            type="text"
            class="form-control floating"
            placeholder=" "
            v-model="patient.physical_activity"
          />
          <label class="focus-label">{{
            $t("register.physical_activity")
          }}</label>

          <div class="py-2" v-if="v$.physical_activity.$error">
            <small
              v-if="v$.physical_activity.required.$invalid"
              class="text-warning d-block"
            >
              {{ $t("validation.requiredField") }}
            </small>
          </div>
        </div>
      </div>

      <!-- healthy_habits -->
      <div class="col-lg-6 d-flex align-items-end">
        <div class="form-focus h-auto w-100">
          <input
            type="text"
            class="form-control floating"
            placeholder=" "
            v-model="patient.healthy_habits"
          />
          <label class="focus-label">{{ $t("register.healthy_habits") }}</label>

          <div class="py-2" v-if="v$.healthy_habits.$error">
            <small
              v-if="v$.healthy_habits.required.$invalid"
              class="text-warning d-block"
            >
              {{ $t("validation.requiredField") }}
            </small>
          </div>
        </div>
      </div>

      <!-- medical_history -->
      <div class="col-lg-6 d-flex align-items-end">
        <div class="form-focus h-auto w-100">
          <input
            type="text"
            class="form-control floating"
            placeholder=" "
            v-model="patient.medical_history"
          />
          <label class="focus-label">{{
            $t("register.medical_history")
          }}</label>

          <div class="py-2" v-if="v$.medical_history.$error">
            <small
              v-if="v$.medical_history.required.$invalid"
              class="text-warning d-block"
            >
              {{ $t("validation.requiredField") }}
            </small>
          </div>
        </div>
      </div>
      <div class="">
        <p>
          <small>
            {{ $t("fillAllInput") }}
          </small>
          <b-spinner small type="grow" class="mx-2" v-if="pdfLoad"></b-spinner>
          <br />
          <a href="#" class="text-primary" @click="getPatientContract">
            {{ $t("agreeTermsConditions") }}
          </a>
        </p>
        <router-link class="forgot-link" to="/login">
          {{ $t("register.alreadyHaveAccount") }}
        </router-link>
      </div>

      <b-button
        :disabled="disabledBtn"
        class="btn btn-primary btn-lg login-btn"
        @click="submitForm"
      >
        <span v-if="!loading">{{ $t("buttons.sign_up") }}</span>
        <b-spinner v-else></b-spinner>
      </b-button>
    </form>
    <a-modal
      v-model:open="open"
      :title="$t('notice')"
      centered
      width="80%"
      :footer="null"
      @cancel="open = false"
    >
      <div class="mb-4">
        <iframe
          :src="pdfUrl"
          width="100%"
          height="500px"
          style="border: none"
        ></iframe>
      </div>
      <div class="d-flex">
        <b-button class="btn btn-danger btn-lg login-btn" @click="open = false">
          <span>{{ $t("reject") }}</span>
        </b-button>
        <b-button
          class="btn btn-primary mx-2 btn-lg login-btn"
          @click="(open = false), (disabledBtn = false)"
        >
          <span>{{ $t("accept") }}</span>
        </b-button>
      </div>
    </a-modal>
  </div>
</template>

<script setup>
import { getCountries } from "@/api/lookups";
import { patientRegister, patientPreviewContract } from "@/api/patient";
import { objectToFormData } from "@/utils/helpers";
import { onMounted, computed, reactive, ref } from "vue";
import useVuelidate from "@vuelidate/core";
import { email, required } from "@vuelidate/validators";
import { useRouter } from "vue-router";

const router = useRouter();

const countries = ref([]);
const dateFormat = "dd-MM-yyyy";
const pdfUrl = ref("");
const pdfLoad = ref(false);
const open = ref(false);
const disabledBtn = ref(true);

const patient = reactive({
  first_name: "",
  last_name: "",
  email: "",
  mobile: null,
  photo: null,
  nationality_id: null,
  country_id: null,
  gender: null,
  date_of_birth: null,
  national_id_number: "",
  chronic_diseases: "",
  endorsement: "",
  current_medications: "",
  medical_allergies: "",
  chronic_conditions: "",
  previous_surgeries: "",
  laboratory_tests: "",
  physical_activity: "",
  healthy_habits: "",
  medical_history: "",
});

const genders = computed(() => [
  { id: "male", text: "Male" },
  { id: "female", text: "female" },
]);

const rules = {
  first_name: { required },
  last_name: { required },
  email: { required, email },
  mobile: { required },
  photo: { required },
  nationality_id: { required },
  country_id: { required },
  gender: { required },
  date_of_birth: { required },
  national_id_number: { required },
  chronic_diseases: { required },
  endorsement: { required },
  current_medications: { required },
  medical_allergies: { required },
  chronic_conditions: { required },
  previous_surgeries: { required },
  laboratory_tests: { required },
  physical_activity: { required },
  healthy_habits: { required },
  medical_history: { required },
};
const loading = ref(false);
const v$ = useVuelidate(rules, patient);

onMounted(async () => {
  const countriesList = await getCountries();
  countries.value = countriesList.data;
});

const checkphone = (e) => {
  if (e.valid) {
    patient.mobile = e.number;
  }
};

const getPatientContract = async () => {
  await v$.value.$validate();
  if (v$.value.$invalid) {
    return;
  }
  pdfLoad.value = true;

  const payload = objectToFormData({
    ...patient,
    nationality_id: patient.nationality_id.id,
    country_id: patient.country_id.id,
    gender: patient.gender.id,
    date_of_birth: patient.date_of_birth.toLocaleDateString("es-CL"),
  });

  try {
    let res = await patientPreviewContract(payload);
    if (res) {
      pdfUrl.value = res.data.url;
      open.value = true;
    }
  } catch (error) {
    console.log(error);
  } finally {
    pdfLoad.value = false;
  }
};

const submitForm = async () => {
  await v$.value.$validate();
  if (v$.value.$invalid) {
    return;
  }
  loading.value = true;

  const fd = objectToFormData({
    ...patient,
    nationality_id: patient.nationality_id.id,
    country_id: patient.country_id.id,
    gender: patient.gender.id,
    date_of_birth: patient.date_of_birth.toLocaleDateString("es-CL"),
  });

  try {
    await patientRegister(fd);
    router.push("/");
    reset();
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
};

const reset = () => {
  patient.value = {
    first_name: "",
    last_name: "",
    email: "",
    mobile: null,
    photo: null,
    nationality_id: null,
    country_id: null,
    gender: null,
    date_of_birth: null,
    national_id_number: "",
    chronic_diseases: "",
    endorsement: "",
    current_medications: "",
    medical_allergies: "",
    chronic_conditions: "",
    previous_surgeries: "",
    laboratory_tests: "",
    physical_activity: "",
    healthy_habits: "",
    medical_history: "",
  };
};
</script>

<style lang="scss" scoped></style>
