<template>
  <div class="main-wrapper">
    <layoutheader></layoutheader>
    <breadcrumb1 :title="this.$t('profile_settings')" :text="this.$t('home')" :text1="this.$t('profile_settings')" />
    <div class="content">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-xl-3 theiaStickySidebar">
            <!-- Profile Sidebar -->
            <profilesidebar></profilesidebar>
            <!-- /Profile Sidebar -->
          </div>

          <div class="col-lg-8 col-xl-9">
            <form>
              <div class="setting-card">
                <div class="change-avatar img-upload">
                  <div class="profile-img">
                    <img :src="photoUrl" alt="" />
                  </div>
                  <div class="upload-img">
                    <h5>{{ $t("profile_image") }}</h5>
                    <div class="imgs-load d-flex align-items-center">
                      <div class="change-photo">
                        {{ $t("upload_now") }}
                        <input
                          type="file"
                          class="upload"
                          @change="changePhoto"
                          accept=".jpg,.png,.jpeg,.webp"
                        />
                      </div>
                      <a href="#" class="upload-remove" @click="removePhoto"
                        >{{ $t("remove") }}</a
                      >
                    </div>
                    <p class="form-text">
                      {{ $t("image_instruction_uploads") }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="setting-title">
                <h5> {{ $t("information") }}</h5>
              </div>
              <div class="row gy-3">
                <div class="col-lg-6">
                  <div class="form-focus h-auto">
                    <input
                      type="text"
                      placeholder=" "
                      class="form-control floating"
                      v-model="patient.first_name"
                    />
                    <label class="focus-label">{{
                      $t("register.first_name")
                    }}</label>

                    <div class="py-2" v-if="v$.patient.first_name.$error">
                      <small
                        v-if="v$.patient.first_name.required.$invalid"
                        class="text-warning d-block"
                      >
                        {{ $t("validation.requiredField") }}
                      </small>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-focus h-auto">
                    <input
                      type="text"
                      class="form-control floating"
                      placeholder=" "
                      v-model="patient.last_name"
                    />
                    <label class="focus-label">{{
                      $t("register.last_name")
                    }}</label>

                    <div class="py-2" v-if="v$.patient.last_name.$error">
                      <small
                        v-if="v$.patient.last_name.required.$invalid"
                        class="text-warning d-block"
                      >
                        {{ $t("validation.requiredField") }}
                      </small>
                    </div>
                  </div>
                </div>

                <!-- email -->
                <div class="col-lg-6">
                  <div class="form-focus h-auto">
                    <input
                      type="email"
                      class="form-control floating"
                      placeholder=" "
                      v-model="patient.email"
                    />
                    <label class="focus-label">{{
                      $t("register.email")
                    }}</label>

                    <div class="py-2" v-if="v$.patient.email.$error">
                      <small
                        v-if="v$.patient.email.required.$invalid"
                        class="text-warning d-block"
                      >
                        {{ $t("validation.requiredField") }}
                      </small>
                      <small
                        v-else-if="v$.patient.email.email.$invalid"
                        class="text-warning d-block"
                      >
                        {{ $t("validation.email") }}
                      </small>
                    </div>
                  </div>
                </div>

                <!-- mobile -->
                <div class="col-lg-6">
                  <div class="form-focus h-auto">
                    <input
                      type="text"
                      class="form-control floating"
                      placeholder=" "
                      v-model="patient.mobile"
                    />
                    <label class="focus-label">{{
                      $t("register.mobile")
                    }}</label>
                    <div class="py-2" v-if="v$.patient.mobile.$error">
                      <small
                        v-if="v$.patient.mobile.required.$invalid"
                        class="text-warning d-block"
                      >
                        {{ $t("validation.requiredField") }}
                      </small>
                    </div>
                  </div>
                </div>

                <!-- nationality_id -->
                <div class="col-lg-6">
                  <div class="form-wrap">
                    <label class="col-form-label">{{
                      $t("register.nationality")
                    }}</label>
                    <select
                      class="form-control"
                      v-model="patient.nationality_id"
                    >
                      <option
                        v-for="(item, i) in countries"
                        :key="i"
                        :value="item.id"
                      >
                        {{ item.lang }}
                      </option>
                    </select>

                    <div class="py-2" v-if="v$.patient.nationality_id.$error">
                      <small
                        v-if="v$.patient.nationality_id.required.$invalid"
                        class="text-warning d-block"
                      >
                        {{ $t("validation.requiredField") }}
                      </small>
                    </div>
                  </div>
                </div>
                <!-- country_id -->
                <div class="col-lg-6">
                  <div class="form-wrap">
                    <label class="col-form-label">{{
                      $t("register.country")
                    }}</label>

                    <select class="form-control" v-model="patient.country_id">
                      <option
                        v-for="(item, i) in countries"
                        :key="i"
                        :value="item.id"
                      >
                        {{ item.lang }}
                      </option>
                    </select>

                    <div class="py-2" v-if="v$.patient.country_id.$error">
                      <small
                        v-if="v$.patient.country_id.required.$invalid"
                        class="text-warning d-block"
                      >
                        {{ $t("validation.requiredField") }}
                      </small>
                    </div>
                  </div>
                </div>
                <!-- gender -->
                <div class="col-lg-6">
                  <div class="form-wrap">
                    <label class="col-form-label">{{
                      $t("register.gender")
                    }}</label>
                    <select
                      class="form-control"
                      v-model="patient.gender"
                      disabled
                    >
                      <option
                        v-for="(item, i) in genders"
                        :key="i"
                        :value="item.id"
                      >
                        {{ item.text }}
                      </option>
                    </select>

                    <div class="py-2" v-if="v$.patient.gender.$error">
                      <small
                        v-if="v$.patient.gender.required.$invalid"
                        class="text-warning d-block"
                      >
                        {{ $t("validation.requiredField") }}
                      </small>
                    </div>
                  </div>
                </div>

                <!-- birthdate -->
                <div class="col-md-6">
                  <div class="form-wrap mb-0">
                    <label class="col-form-label">
                      {{ $t("register.birthdate") }}
                    </label>
                    <div class="form-icon">
                      <input
                        disabled
                        class="form-control"
                        type="date"
                        v-model="patient.date_of_birth"
                        :max="currentDate"
                      />
                    </div>

                    <div class="py-2" v-if="v$.patient.date_of_birth.$error">
                      <small
                        v-if="v$.patient.date_of_birth.required.$invalid"
                        class="text-warning d-block"
                      >
                        {{ $t("validation.requiredField") }}
                      </small>
                    </div>
                  </div>
                </div>

                <!-- national_id_number -->
                <div class="col-lg-6 d-flex align-items-end">
                  <div class="form-focus h-auto w-100">
                    <input
                      type="text"
                      class="form-control floating"
                      placeholder=" "
                      v-model="patient.national_id_number"
                    />
                    <label class="focus-label">{{
                      $t("register.national_id_number")
                    }}</label>

                    <div class="py-2" v-if="v$.patient.last_name.$error">
                      <small
                        v-if="v$.patient.national_id_number.required.$invalid"
                        class="text-warning d-block"
                      >
                        {{ $t("validation.requiredField") }}
                      </small>
                    </div>
                  </div>
                </div>

                <!-- chronic_diseases -->
                <div class="col-lg-6 d-flex align-items-end">
                  <div class="form-focus h-auto w-100">
                    <input
                      type="text"
                      class="form-control floating"
                      placeholder=" "
                      v-model="patient.chronic_diseases"
                    />
                    <label class="focus-label">{{
                      $t("register.chronic_diseases")
                    }}</label>

                    <div class="py-2" v-if="v$.patient.chronic_diseases.$error">
                      <small
                        v-if="v$.patient.chronic_diseases.required.$invalid"
                        class="text-warning d-block"
                      >
                        {{ $t("validation.requiredField") }}
                      </small>
                    </div>
                  </div>
                </div>

                <!-- endorsement -->
                <div class="col-lg-6 d-flex align-items-end">
                  <div class="form-focus h-auto w-100">
                    <input
                      type="text"
                      class="form-control floating"
                      placeholder=" "
                      v-model="patient.endorsement"
                    />
                    <label class="focus-label">{{
                      $t("register.endorsement")
                    }}</label>

                    <div class="py-2" v-if="v$.patient.endorsement.$error">
                      <small
                        v-if="v$.patient.endorsement.required.$invalid"
                        class="text-warning d-block"
                      >
                        {{ $t("validation.requiredField") }}
                      </small>
                    </div>
                  </div>
                </div>

                <!-- current_medications -->
                <div class="col-lg-6 d-flex align-items-end">
                  <div class="form-focus h-auto w-100">
                    <input
                      type="text"
                      class="form-control floating"
                      placeholder=" "
                      v-model="patient.current_medications"
                    />
                    <label class="focus-label">{{
                      $t("register.current_medications")
                    }}</label>

                    <div
                      class="py-2"
                      v-if="v$.patient.current_medications.$error"
                    >
                      <small
                        v-if="v$.patient.current_medications.required.$invalid"
                        class="text-warning d-block"
                      >
                        {{ $t("validation.requiredField") }}
                      </small>
                    </div>
                  </div>
                </div>
                <!-- medical_allergies -->
                <div class="col-lg-6 d-flex align-items-end">
                  <div class="form-focus h-auto w-100">
                    <input
                      type="text"
                      class="form-control floating"
                      placeholder=" "
                      v-model="patient.medical_allergies"
                    />
                    <label class="focus-label">{{
                      $t("register.medical_allergies")
                    }}</label>

                    <div
                      class="py-2"
                      v-if="v$.patient.medical_allergies.$error"
                    >
                      <small
                        v-if="v$.patient.medical_allergies.required.$invalid"
                        class="text-warning d-block"
                      >
                        {{ $t("validation.requiredField") }}
                      </small>
                    </div>
                  </div>
                </div>

                <!-- previous_surgeries -->
                <div class="col-lg-6 d-flex align-items-end">
                  <div class="form-focus h-auto w-100">
                    <input
                      type="text"
                      class="form-control floating"
                      placeholder=" "
                      v-model="patient.previous_surgeries"
                    />
                    <label class="focus-label">{{
                      $t("register.previous_surgeries")
                    }}</label>

                    <div
                      class="py-2"
                      v-if="v$.patient.previous_surgeries.$error"
                    >
                      <small
                        v-if="v$.patient.previous_surgeries.required.$invalid"
                        class="text-warning d-block"
                      >
                        {{ $t("validation.requiredField") }}
                      </small>
                    </div>
                  </div>
                </div>

                <!-- laboratory_tests -->
                <div class="col-lg-6 d-flex align-items-end">
                  <div class="form-focus h-auto w-100">
                    <input
                      type="text"
                      class="form-control floating"
                      placeholder=" "
                      v-model="patient.laboratory_tests"
                    />
                    <label class="focus-label">{{
                      $t("register.laboratory_tests")
                    }}</label>

                    <div class="py-2" v-if="v$.patient.laboratory_tests.$error">
                      <small
                        v-if="v$.patient.laboratory_tests.required.$invalid"
                        class="text-warning d-block"
                      >
                        {{ $t("validation.requiredField") }}
                      </small>
                    </div>
                  </div>
                </div>

                <!-- physical_activity -->
                <div class="col-lg-6 d-flex align-items-end">
                  <div class="form-focus h-auto w-100">
                    <input
                      type="text"
                      class="form-control floating"
                      placeholder=" "
                      v-model="patient.physical_activity"
                    />
                    <label class="focus-label">{{
                      $t("register.physical_activity")
                    }}</label>

                    <div
                      class="py-2"
                      v-if="v$.patient.physical_activity.$error"
                    >
                      <small
                        v-if="v$.patient.physical_activity.required.$invalid"
                        class="text-warning d-block"
                      >
                        {{ $t("validation.requiredField") }}
                      </small>
                    </div>
                  </div>
                </div>

                <!-- healthy_habits -->
                <div class="col-lg-6 d-flex align-items-end">
                  <div class="form-focus h-auto w-100">
                    <input
                      type="text"
                      class="form-control floating"
                      placeholder=" "
                      v-model="patient.healthy_habits"
                    />
                    <label class="focus-label">{{
                      $t("register.healthy_habits")
                    }}</label>

                    <div class="py-2" v-if="v$.patient.healthy_habits.$error">
                      <small
                        v-if="v$.patient.healthy_habits.required.$invalid"
                        class="text-warning d-block"
                      >
                        {{ $t("validation.requiredField") }}
                      </small>
                    </div>
                  </div>
                </div>

                <!-- medical_history -->
                <div class="col-lg-6 d-flex align-items-end">
                  <div class="form-focus h-auto w-100">
                    <input
                      type="text"
                      class="form-control floating"
                      placeholder=" "
                      v-model="patient.medical_history"
                    />
                    <label class="focus-label">{{
                      $t("register.medical_history")
                    }}</label>

                    <div class="py-2" v-if="v$.patient.medical_history.$error">
                      <small
                        v-if="v$.patient.medical_history.required.$invalid"
                        class="text-warning d-block"
                      >
                        {{ $t("validation.requiredField") }}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex col-12 pt-3">
                <b-button
                  class="btn btn-primary mx-2 btn-lg login-btn"
                  @click="updateData"
                  :disabled="loadingForm"
                >
                  <span v-if="!loadingForm"> {{ $t("update") }}</span>
                  <b-spinner v-else></b-spinner>
                </b-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <footerindex></footerindex>
</template>
<script>
import { getCountries } from "@/api/lookups";
import { MyAccount, updatePatientData } from "@/api/patient";
import { required, email } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { mapGetters } from "vuex";
import { objectToFormData } from "@/utils/helpers";

export default {
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      title: "Profile Settings",
      text: "Home",
      text1: "Profile Settings",
      lodaing: false,
      patientDetails: {},
      countries: [],
      genders: [
        { id: "male", text: "Male" },
        { id: "female", text: "Female" },
      ],
      photoUrl: "",
      loadingForm: false,
      patient: {
        first_name: "",
        last_name: "",
        email: "",
        mobile: null,
        photo: null,
        nationality_id: null,
        country_id: null,
        gender: null,
        date_of_birth: null,
        national_id_number: "",
        chronic_diseases: "",
        endorsement: "",
        current_medications: "",
        medical_allergies: "",
        previous_surgeries: "",
        laboratory_tests: "",
        physical_activity: "",
        healthy_habits: "",
        medical_history: "",
        id: "",
      },
    };
  },
  validations() {
    return {
      patient: {
        first_name: { required },
        last_name: { required },
        email: { required, email },
        mobile: { required },
        nationality_id: { required },
        country_id: { required },
        gender: { required },
        date_of_birth: { required },
        national_id_number: { required },
        chronic_diseases: { required },
        endorsement: { required },
        current_medications: { required },
        medical_allergies: { required },
        previous_surgeries: { required },
        laboratory_tests: { required },
        physical_activity: { required },
        healthy_habits: { required },
        medical_history: { required },
      },
    };
  },
  computed: {
    ...mapGetters(["currentDate"]),
    photo() {
      // Returning photoUrl created by FileReader
      return this.photoUrl || ""; // This will be the image source
    },
  },
  created() {
    this.getCountries();
    this.getDetails();
  },
  methods: {
    async updateData() {
      await this.v$.$validate();
      if (this.v$.$invalid) {
        return;
      }
      this.loadingForm = true;
      if (!this.patient.photo) {
        delete this.patient.photo;
      }
      const fd = objectToFormData({
        ...this.patient,
      });
      try {
        let res = await updatePatientData(fd);
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingForm = false;
      }
    },
    async getCountries() {
      const countriesList = await getCountries();
      this.countries = countriesList.data;
    },
    checkphone(e) {
      if (e.valid) {
        this.patient.mobile = e.number;
      }
    },
    async getDetails() {
      this.lodaing = true;
      try {
        let response = await MyAccount();
        if (response) {
          const res = response?.data;
          const patient = res.patient;
          this.patient.first_name = patient.first_name || "";
          this.patient.last_name = patient.last_name || "";
          this.patient.email = res.email || "";
          this.patient.mobile = patient.mobile || null;
          this.photoUrl = patient.photo || null;
          this.patient.nationality_id = patient.nationality_id || null;
          this.patient.country_id = patient.country_id || null;
          this.patient.gender = patient.gender || null;
          this.patient.date_of_birth = patient.birthdate || null;
          this.patient.national_id_number = patient.national_id_number || "";
          this.patient.chronic_diseases = patient.chronic_diseases || "";
          this.patient.endorsement = patient.endorsement || "";
          this.patient.current_medications = patient.current_medications || "";
          this.patient.medical_allergies = patient.medical_allergies || "";
          this.patient.previous_surgeries = patient.previous_surgeries || "";
          this.patient.laboratory_tests = patient.laboratory_tests || "";
          this.patient.physical_activity = patient.physical_activity || "";
          this.patient.healthy_habits = patient.healthy_habits || "";
          this.patient.medical_history = patient.medical_history || "";
          // console.log(this.patientDetails, "dsdsd");
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.lodaing = false;
      }
    },
    submitForm() {
      this.$router.push("/profile-settings");
    },
    changePhoto(event) {
      const file = event.target.files[0];
      if (file) {
        this.patient.photo = file;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.photoUrl = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    removePhoto() {
      this.patient.photo = null;
      this.photoUrl = "";
    },
  },
};
</script>
