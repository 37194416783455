<template>
  <div class="main-wrapper">
    <layoutheader></layoutheader>
    <!-- Page Content -->
    <div class="content top-space">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-8 mx-auto">
            <!-- Register Content -->
            <div class="account-content">
              <div class="mb-4">
                <b-nav pills>
                  <b-nav-item active>{{
                    $t("register.patientRegister")
                  }}</b-nav-item>
                  <b-nav-item>
                    <router-link to="/doctor-register">
                      {{ $t("register.doctorRegister") }}
                    </router-link>
                  </b-nav-item>
                </b-nav>
              </div>

              <div>
                <PatientForm />
              </div>
            </div>
            <!-- /Register Content -->
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->
    <footerindex />
  </div>
</template>
<script>
import PatientForm from "@/components/frontend/forms/patient-form.vue";

export default {
  components: {
    PatientForm,
  },
  data() {
    return {
      showForm: false,
    };
  },
  methods: {},
};
</script>
