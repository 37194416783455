import axios from "axios";
import store from "@/store";

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 1000000, // request timeout
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    const locale = store.getters.currentLang;
    const userToken = store.getters.token;

    // Set Authorization token
    if (userToken) {
      config.headers["Authorization"] = `Bearer ${userToken}`;
    }
    if (locale) {
      config.headers["locale"] = locale;
      config.headers["Accept-language"] = locale;
    }
    config.headers["Accept"] = "application/json";
    config.headers["Access-Control-Allow-Origin"] = "*";
    config.headers["Access-Control-Allow-Headers"] = "*";
    // locale: userLang, 'en' || 'ar'
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  (response) => {
    const res = response.data;

    if (res.code === 200 || res.code === 202) {
      if (res.message) {
        handleModalMessage(res.message, true);
      }
      return res;
    }
    if (res.success) {
      return res.model;
    }
    if (res.code !== 20000) {
      return res;
    } else {
      return res;
    }
  },
  (error) => {
    if (error.response && error.response.status === 404) {
      this.$router.push("/error");
      return Promise.resolve();
    }
    if (error.response && error.response.status === 422) {
      handleModalMessage(error.response.data.message, false);
      return Promise.resolve();
    }
    if (
      error.response &&
      error.response.status === 401 &&
      error.response.data.message
    ) {
      handleModalMessage(error.response.data.message, false);
      return Promise.resolve();
    }
    if (error.response && error.response.status === 400) {
      handleModalMessage(error.response.data.message, false);
      return Promise.resolve();
    }
    return Promise.reject(error);
  }
);

const handleModalMessage = (message) => {
  store.commit("SET_MESSAGE", {
    message,
    open: true,
  });
  setTimeout(() => {
    //todo
    let modalConfirmation = document.querySelector(
      ".custom-modal-confirmation"
    );
    if (modalConfirmation) {
      modalConfirmation.click();
    }
  }, 3000);
};

export default service;
