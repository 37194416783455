<template>
  <div class="">
    <section class="work-section" v-if="!isLoggedIn">
      <div class="container">
        <div class="work-details">
          <div class="section-header-one aos" data-aos="fade-up">
            <h2 class="section-title">Register as a doctor or a ptient</h2>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-6 aos" data-aos="fade-up">
              <router-link to="/doctor-register">
                <div class="work-info d-flex align-items-center">
                  <div class="work-icon">
                    <span
                      ><img src="@/assets/img/icons/work-01.svg" alt="Image"
                    /></span>
                  </div>
                  <div class="work-content">
                    <h5>Doctor Register</h5>
                  </div>
                </div>
              </router-link>
            </div>
            <div class="col-lg-6 col-md-6 aos" data-aos="fade-up">
              <router-link to="/patient-register">
                <div class="work-info d-flex align-items-center">
                  <div class="work-icon">
                    <span
                      ><img src="@/assets/img/icons/work-01.svg" alt="Image"
                    /></span>
                  </div>
                  <div class="work-content">
                    <h5>Patient Register</h5>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="articles-section d-none">
      <div class="container">
        <div class="row">
          <div class="col-md-12 aos" data-aos="fade-up">
            <div class="section-header-one text-center">
              <h2 class="section-title">Latest Articles</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div
            class="col-lg-6 col-md-6 d-flex aos"
            data-aos="fade-up"
            v-for="item in IndexArticles"
            :key="item.id"
          >
            <div class="articles-grid w-100">
              <div class="articles-info">
                <div class="articles-left">
                  <router-link to="/">
                    <div class="articles-img">
                      <!-- <img
                      :src="require(`@/assets/img/blog/${item.Image}`)"
                      class="img-fluid"
                      alt="Image"
                    /> -->
                    </div>
                  </router-link>
                </div>
                <div class="articles-right">
                  <div class="articles-content">
                    <ul class="articles-list nav">
                      <li><i class="feather-user"></i> {{ item.Name }}</li>
                      <li><i class="feather-calendar"></i> {{ item.Day }}</li>
                    </ul>
                    <h4>
                      <router-link to="/">{{ item.Title }}</router-link>
                    </h4>
                    <p>{{ item.Content }}</p>
                    <router-link to="/" class="btn">Read More</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import AOS from "aos";
import "aos/dist/aos.css";
import IndexArticles from "@/assets/json/indexarticles.json";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      IndexArticles: IndexArticles,
    };
  },
  computed: {
    ...mapGetters(["isLoggedIn"]),
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
};
</script>
