import Cookies from "js-cookie";

const UserLang = "User-Lang";

export function setUserLang(payload) {
  return Cookies.set(UserLang, payload);
}
export function getUserLang() {
  return Cookies.get(UserLang);
}

export function objectToFormData(
  obj,
  formData = new FormData(),
  parentKey = ""
) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      const formKey = parentKey ? `${parentKey}[${key}]` : key;

      if (
        value instanceof File ||
        value instanceof Blob ||
        value instanceof Date
      ) {
        formData.append(formKey, value);
      } else if (Array.isArray(value)) {
        value.forEach((item, index) => {
          if (item instanceof File || item instanceof Blob) {
            formData.append(`${formKey}[${index}]`, item);
          } else {
            objectToFormData({ [index]: item }, formData, formKey);
          }
        });
      } else if (value && typeof value === "object") {
        objectToFormData(value, formData, formKey);
      } else {
        formData.append(formKey, value);
      }
    }
  }
  return formData;
}
