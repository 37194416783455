<template>
  <div class="main-wrapper">
    <layoutheader></layoutheader>
    <breadcrumb1 :title="title" :text="text" :text1="text1" />

    <!-- Page Content -->
    <div class="content">
      <div class="container">
        <div class="row">
          <doctorsidebar></doctorsidebar>

          <div class="col-md-7 col-lg-8 col-xl-9">
            <div class="row">
              <div class="col-xl-4 d-flex">
                <div class="dashboard-box-col w-100">
                  <div class="dashboard-widget-box">
                    <div class="dashboard-content-info">
                      <h6>{{ $t("totalPatients") }}</h6>
                      <h4>{{ dashboardData.total_patients }}</h4>
                      <!-- <span class="text-success"
                        ><i class="fa-solid fa-arrow-up"></i>15% From Last
                        Week</span
                      > -->
                    </div>
                    <div class="dashboard-widget-icon">
                      <span class="dash-icon-box"
                        ><i class="fa-solid fa-user-injured"></i
                      ></span>
                    </div>
                  </div>
                  <div class="dashboard-widget-box">
                    <div class="dashboard-content-info">
                      <h6>{{ $t("patientsToday") }}</h6>
                      <h4>978</h4>
                      <!-- <span class="text-danger"
                        ><i class="fa-solid fa-arrow-up"></i>15% From
                        Yesterday</span
                      > -->
                    </div>
                    <div class="dashboard-widget-icon">
                      <span class="dash-icon-box"
                        ><i class="fa-solid fa-user-clock"></i
                      ></span>
                    </div>
                  </div>
                  <div class="dashboard-widget-box">
                    <div class="dashboard-content-info">
                      <h6>{{ $t("appointmentsToday") }}</h6>
                      <h4>{{ dashboardData.today_appointments }}</h4>
                      <!-- <span class="text-success"
                        ><i class="fa-solid fa-arrow-up"></i>20% From
                        Yesterday</span
                      > -->
                    </div>
                    <div class="dashboard-widget-icon">
                      <span class="dash-icon-box"
                        ><i class="fa-solid fa-calendar-days"></i
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-7 d-flex">
                <div class="dashboard-card w-100">
                  <div class="dashboard-card-head">
                    <div class="header-title">
                      <h5>{{ $t("appointments") }}</h5>
                    </div>
                    <div class="card-view-link">
                      <router-link to="/appointments">
                        {{ $t("viewAll") }}
                      </router-link>
                    </div>
                  </div>
                  <div class="dashboard-card-body">
                    <div class="table-responsive">
                      <table class="table dashboard-table">
                        <tbody>
                          <tr v-for="(item, i) in appointments" :key="i">
                            <td>
                              <div class="table-noti-info">
                                <div class="table-noti-message">
                                  <h6>
                                    <router-link
                                      :to="`/doctor-appointment-details?app=${item.id}`"
                                      >{{ item.patient_name }}
                                      <span
                                        class="badge ms-2"
                                        :class="
                                          item.status == 'cancelled'
                                            ? 'bg-danger'
                                            : 'bg-info'
                                        "
                                      >
                                        {{ item.status }}
                                      </span>
                                      {{ item.time_from }} -
                                      {{ item.time_to }}</router-link
                                    >
                                  </h6>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->
  </div>
  <footerindex></footerindex>
</template>

<script>
import { revenueChart, appointmentChart } from "./data";
import { doctorDashboard, doctorAppointments } from "@/api/doctor";

export default {
  data() {
    return {
      title: "Dashboard",
      text: "Home",
      text1: "Dashboard",
      revenueChart: revenueChart,
      appointmentChart: appointmentChart,
      loading: false,
      dashboardData: {},
      appointments: [],
    };
  },
  created() {
    this.getDoctorDashboard();
    this.getAppointments();
  },
  methods: {
    async getDoctorDashboard() {
      this.loading = true;
      try {
        let res = await doctorDashboard();
        if (res) {
          this.dashboardData = res.data;
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
    async getAppointments() {
      try {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, "0");
        const day = String(today.getDate()).padStart(2, "0");
        const currentDate = `${year}-${month}-${day}`;

        let payload = {
          status: null,
          date_from: currentDate,
          date_to: currentDate,
          page: "",
        };
        const response = await doctorAppointments();

        if (response) {
          this.appointments = response.data.data;
        }
      } catch (error) {
        console.error("Error fetching roles list:", error);
      }
    },
  },
};
</script>
