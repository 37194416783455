<template>
  <ul class="main-nav" v-bind:class="$route.meta.headerMainNavClass">
    <li class="has-submenu megamenu" :class="homeMenu ? 'active' : 'notactive'">
      <router-link to="/">Home</router-link>
    </li>
    <li class="has-submenu" :class="doctorMenu ? 'active' : 'notactive'">
      <a href="javascript:void(0);" @click="toggleVisibilitydoctor"
        >Doctors <i class="fas fa-chevron-down"></i
      ></a>
      <ul
        class="submenu"
        :style="{ display: isVisibledoctor ? 'block' : 'none' }"
      >
        <li :class="currentPath == 'doctor-dashboard' ? 'active' : 'notactive'">
          <router-link to="/doctor-dashboard">Doctor Dashboard</router-link>
        </li>
        <li :class="currentPath == 'appointments' ? 'active' : 'notactive'">
          <router-link to="/appointments">Appointments</router-link>
        </li>
        <li :class="currentPath == 'schedule-timings' ? 'active' : 'notactive'">
          <router-link to="/schedule-timings">Schedule Timing</router-link>
        </li>
        <li :class="currentPath == 'my-patients' ? 'active' : 'notactive'">
          <router-link to="/my-patients">Patients List</router-link>
        </li>
        <li
          :class="
            currentPath == 'patient-profile' ||
            currentPath == 'edit-prescription' ||
            currentPath == 'add-billing'
              ? 'active'
              : 'notactive'
          "
        >
          <router-link to="/patient-profile">Patients Profile</router-link>
        </li>

        <li
          :class="
            currentPath == 'doctor-profile-settings' ? 'active' : 'notactive'
          "
        >
          <router-link to="/doctor-profile-settings"
            >Profile Settings</router-link
          >
        </li>
        <li :class="currentPath == 'reviews' ? 'active' : 'notactive'">
          <router-link to="/reviews">Reviews</router-link>
        </li>
      </ul>
    </li>
    <li class="has-submenu" :class="patientsMenu ? 'active' : 'notactive'">
      <a href="javascript:void(0);" @click="toggleVisibilitypatients"
        >Patients <i class="fas fa-chevron-down"></i
      ></a>
      <ul
        class="submenu"
        :style="{ display: isVisiblepatients ? 'block' : 'none' }"
      >
        <li
          class="has-submenu"
          :class="currentPath == 'search' ? 'active' : 'notactive'"
        >
          <a href="javascript:void(0);" @click="toggleVisibilitysearch"
            >Search Doctor</a
          >
          <ul
            class="submenu inner-submenu"
            :style="{ display: isVisiblepatientssearch ? 'block' : 'none' }"
          >
            <li :class="currentPath == 'search' ? 'active' : 'notactive'">
              <router-link to="/search">Search Doctor 1</router-link>
            </li>
          </ul>
        </li>
        <li
          class="has-submenu"
          :class="currentPath == 'doctor-profile' ? 'active' : 'notactive'"
        >
          <a href="javascript:void(0);">Doctor Profile</a>
          <ul class="submenu inner-submenu">
            <li
              :class="currentPath == 'doctor-profile' ? 'active' : 'notactive'"
            >
              <router-link to="doctor-profile">Doctor Profile 1</router-link>
            </li>
          </ul>
        </li>
        <li :class="currentPath == 'profile-settings' ? 'active' : 'notactive'">
          <router-link to="/profile-settings">Profile Settings</router-link>
        </li>
      </ul>
    </li>

    <li class="has-submenu" :class="pagesMenu ? 'active' : 'notactive'">
      <a href="javascript:void(0);" @click="toggleVisibilitypages"
        >Pages <i class="fas fa-chevron-down"></i
      ></a>
      <ul
        class="submenu"
        :style="{ display: isVisiblepages ? 'block' : 'none' }"
      >
        <li :class="currentPath == 'about-us' ? 'active' : 'notactive'">
          <router-link to="about-us">About Us</router-link>
        </li>
        <li :class="currentPath == 'contact-us' ? 'active' : 'notactive'">
          <router-link to="contact-us">Contact Us</router-link>
        </li>

        <li class="has-submenu">
          <a href="javascript:void(0);" @click="toggleVisibilityerror"
            >Error Pages</a
          >
          <ul
            class="submenu inner-submenu"
            :style="{ display: isVisibleerror ? 'block' : 'none' }"
          >
            <li :class="currentPath == 'error-404' ? 'active' : 'notactive'">
              <router-link to="/error-404">404 Error</router-link>
            </li>
            <li :class="currentPath == 'error-500' ? 'active' : 'notactive'">
              <router-link to="/error-500">500 Error</router-link>
            </li>
          </ul>
        </li>
        <li :class="currentPath == 'faq' ? 'active' : 'notactive'">
          <router-link to="/faq">FAQ</router-link>
        </li>
        <li :class="currentPath == 'terms-condition' ? 'active' : 'notactive'">
          <router-link to="/terms-condition">Terms & Condition</router-link>
        </li>
        <li :class="currentPath == 'privacy-policy' ? 'active' : 'notactive'">
          <router-link to="/privacy-policy">Privacy Policy</router-link>
        </li>
      </ul>
    </li>
    <li v-if="isHomeTen">
      <div class="dropdown header-dropdown country-flag">
        <a
          class="dropdown-toggle nav-tog"
          data-bs-toggle="dropdown"
          href="javascript:void(0);"
        >
          <img src="@/assets/img/flags/us.png" alt="Img" />English
        </a>
        <div class="dropdown-menu dropdown-menu-end">
          <a href="javascript:void(0);" class="dropdown-item">
            <img src="@/assets/img/flags/fr.png" alt="Img" />French
          </a>
          <a href="javascript:void(0);" class="dropdown-item">
            <img src="@/assets/img/flags/es.png" alt="Img" />Spanish
          </a>
          <a href="javascript:void(0);" class="dropdown-item">
            <img src="@/assets/img/flags/de.png" alt="Img" />German
          </a>
        </div>
      </div>
    </li>
    <li class="login-link">
      <router-link to="/login">Login / Signup</router-link>
    </li>
  </ul>
</template>

<script>
export default {
  data() {
    return {
      isVisiblehome: false,
      isVisibledoctor: false,
      isVisiblepatients: false,
      isVisiblepatientsdoctor: false,
      isVisiblepatientssearch: false,
      isVisiblepatientsbooking: false,
      isVisiblepages: false,
      isVisiblecall: false,
      isVisibleinvoice: false,
      isVisibleauthentication: false,
      isVisibleerror: false,
    };
  },
  methods: {
    toggleVisibilitydoctor() {
      this.isVisibledoctor = !this.isVisibledoctor;
    },

    toggleVisibilitypatients() {
      this.isVisiblepatients = !this.isVisiblepatients;
    },
    toggleVisibilitypatientsdoctors() {
      this.isVisiblepatientsdoctor = !this.isVisiblepatientsdoctor;
    },
    toggleVisibilitysearch() {
      this.isVisiblepatientssearch = !this.isVisiblepatientssearch;
    },
    toggleVisibilitybooking() {
      this.isVisiblepatientsbooking = !this.isVisiblepatientsbooking;
    },
    toggleVisibilitypages() {
      this.isVisiblepages = !this.isVisiblepages;
    },
    toggleVisibilitycall() {
      this.isVisiblecall = !this.isVisiblecall;
    },
    toggleVisibilityinvoice() {
      this.isVisibleinvoice = !this.isVisibleinvoice;
    },
    toggleVisibilityauthentication() {
      this.isVisibleauthentication = !this.isVisibleauthentication;
    },
    toggleVisibilityerror() {
      this.isVisibleerror = !this.isVisibleerror;
    },
  },
  computed: {
    isHomeTen() {
      return this.$route.path === "/index-10";
    },
    currentPath() {
      return this.$route.name;
    },
    homeMenu() {
      return (
        this.$route.name == "index" ||
        this.$route.name == "index-2" ||
        this.$route.name == "index-3" ||
        this.$route.name == "index-4" ||
        this.$route.name == "index-5" ||
        this.$route.name == "index-6" ||
        this.$route.name == "index-7" ||
        this.$route.name == "index-8" ||
        this.$route.name == "index-9" ||
        this.$route.name == "index-10" ||
        this.$route.name == "index-11" ||
        this.$route.name == "index-14"
      );
    },
    doctorMenu() {
      return (
        this.$route.name == "doctor-dashboard" ||
        this.$route.name == "appointments" ||
        this.$route.name == "schedule-timings" ||
        this.$route.name == "my-patients" ||
        this.$route.name == "patient-profile" ||
        this.$route.name == "edit-prescription" ||
        this.$route.name == "add-billing" ||
        this.$route.name == "doctor-profile-settings" ||
        this.$route.name == "reviews"
      );
    },
    patientsMenu() {
      return (
        this.$route.name == "map-grid" ||
        this.$route.name == "map-list" ||
        this.$route.name == "search" ||
        this.$route.name == "doctor-profile" ||
        this.$route.name == "profile-settings"
      );
    },

    pagesMenu() {
      return (
        this.$route.name == "about-us" ||
        this.$route.name == "contact-us" ||
        this.$route.name == "faq" ||
        this.$route.name == "maintenance" ||
        this.$route.name == "coming-soon" ||
        this.$route.name == "terms-condition" ||
        this.$route.name == "privacy-policy" ||
        this.$route.name == "components" ||
        this.$route.name == "invoice-view" ||
        this.$route.name == "login-email" ||
        this.$route.name == "login-phone" ||
        this.$route.name == "doctor-signup" ||
        this.$route.name == "patient-signup" ||
        this.$route.name == "forgot-password" ||
        this.$route.name == "forgot-password2" ||
        this.$route.name == "login-email-otp" ||
        this.$route.name == "login-phone-otp" ||
        this.$route.name == "error-404" ||
        this.$route.name == "error-500"
      );
    },

    redirectReloadIndex11() {
      this.$router.push({ path: "index-11" }).then(() => {
        this.$router.go();
      });
    },
  },
};
</script>
