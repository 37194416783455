<template>
  <div class="main-pagination">
    <!--  -->
    <div class="pagination-content" v-if="pagination.totalPages > 1">
      <b-pagination
        v-model="pagination.pageNumber"
        :total-rows="pagination.totalCount"
        :per-page="pagination.pageSize"
        :first-text="$t('first')"
        :prev-text="$t('previous')"
        :next-text="$t('next')"
        :last-text="$t('last')"
        @change="gotoPage"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      shortCutNumber: null,
    };
  },
  props: {
    pagination: Object,
  },
  methods: {
    gotoPage(page) {
      this.pagination.pageNumber = page;
      this.$emit("changePage", page);
    },
  },
};
</script>

<style scoped>
@import "./index";
</style>
