import request from "@/utils/request";

export function doctorApplication(data) {
  return request({
    url: `/doctor-application`,
    method: "post",
    data: data,
  });
}

export function doctorPreviewContract(data) {
  return request({
    url: `/doctor-application/preview-contract`,
    method: "post",
    data: data,
  });
}

export function checkAvailableTime(data) {
  return request({
    url: `/doctors/check-available-time`,
    method: "get",
    params: data,
  });
}

export function doctorAppointments(data) {
  return request({
    url: `/doctor/appointments`,
    method: "get",
    params: data,
  });
}

export function updateDoctorAppointments(data, id) {
  return request({
    url: `/doctor/appointments/${id}`,
    method: "put",
    params: data,
  });
}

export function getDoctorAppointmentDetails(id) {
  return request({
    url: `/doctor/appointments/${id}`,
    method: "get",
  });
}

export function getDoctorProfile() {
  return request({
    url: `/doctor/profile`,
    method: "get",
  });
}

export function updateDoctorProfile(data) {
  return request({
    url: `/doctor/profile`,
    method: "post",
    data,
  });
}
export function addAvailableDates(data) {
  return request({
    url: `/doctor/available-times/update`,
    method: "post",
    data,
  });
}
export function getAvailableTtimes() {
  return request({
    url: `/doctor/available-times`,
    method: "get",
  });
}
export function cancelDoctorAppointments(id) {
  return request({
    url: `/doctor/appointments/cancel/${id}`,
    method: "post",
  });
}

export function doctorDashboard() {
  return request({
    url: `/doctor/dashboard`,
    method: "get",
  });
}
