<template>
  <div id="login-with-token" class="py-5">
    <div class="py-5 text-center">
      <b-spinner
        variant="primary"
        style="width: 3rem; height: 3rem"
        v-if="loading"
      ></b-spinner>
      <p v-if="errorMessage" class="text-danger">{{ errorMessage }}</p>
      <p v-if="successMessage" class="text-success">{{ successMessage }}</p>
    </div>
  </div>
</template>

<script>
import { loginWithToken } from "@/api/user.js";

export default {
  data() {
    return {
      loading: false,
      errorMessage: null,
      successMessage: null,
    };
  },
  created() {
    if (this?.$route?.query?.token) {
      this.login();
    }
  },
  methods: {
    async login() {
      const token = this.$route.query.token;
      const fd = new FormData();
      fd.append("token", token);
      try {
        const res = await loginWithToken(fd);
        this.$store.dispatch("handleLoginWithToken", res);
      } catch (error) {
        console.error("Error:", error);
      }
    },
  },
};
</script>
